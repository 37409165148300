
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DebounceMixin, FormRulesMixin, VuetifyMixin, BAIconsMixin } from '../../mixins';
import { BAEventAttendeeModel, BestAthletesEventModel } from '../../models/bestAthletesEvent';
import { BAEventOrderModel } from '../../models/bestAthletesEvent/BAEventOrderModel';
import { athleteApi } from '../../api/AthleteApi';
import { AthleteProfileModel } from '../../models/athlete/AthleteProfileModel';
import { bestAthletesEventApi } from '../../api/BestAthletesEventApi';
import { BAEventWaiverModel } from '../../models/bestAthletesEvent/BAEventWaiverModel';
import UserChip from '../ui/UserChip.vue';

@Component({
	components: { UserChip },
})
export default class BestAthletesEventOrderAdmin extends Mixins(DebounceMixin, FormRulesMixin, VuetifyMixin, BAIconsMixin){
	@Prop() event: BestAthletesEventModel;
	@Prop() eventbriteOrderId: string;

	changed(): void{
		this.$emit('changed');
	}
	created(): void{
		this.loadOrderAthleteProfiles();
	}
	athleteProfilesLoading: boolean = false;
	athleteProfiles: AthleteProfileModel[] = [];
	async loadOrderAthleteProfiles(): Promise<void>{
		this.athleteProfilesLoading = true;
		try{
			const athletes = await athleteApi.queryAll({
				$match: {
					['users.id']: this.Order.userId,
				} as any
			}, { limitPerPage: 0 });
			this.athleteProfiles = athletes.docs;
			console.log("Athletes", athletes.docs);
		}catch(e){
			console.error("Failed to Load athlete profiles for order", e);
		}finally{
			this.athleteProfilesLoading = false;
		}
	}

	get OrderUserId(): string | null{
		return this.Order.userId
	}
	get Order(): BAEventOrderModel{
		return this.event.PopulatedOrders.find(o => o.eventbriteId === this.eventbriteOrderId);
	}
	get OrderAttendees(): BAEventAttendeeModel[]{
		return this.event.PopulatedAttendees.filter(a => a.eventbriteOrderId === this.eventbriteOrderId);
	}
	get OrderWaivers(): BAEventWaiverModel[]{
		return this.Order.PopulatedWaivers;
	}
	openOrder(eventbriteOrderId: string): void{
		const url = `https://www.eventbrite.ca/reports?eid=${this.event.eventbriteId}&rid=h&filterby=all,${eventbriteOrderId}`;
		window.open(url, '_blank');
	}
	openWaiver(waiverId: string): void{
		const url = `https://app.smartwaiver.com/api/waivers/${waiverId}/download?inline`;
		window.open(url, '_blank');
	}
	openAthleteDialog(attendee: BAEventAttendeeModel): void{
		this.search = "";
		this.athleteDialogForm.attendee = attendee;
		if(attendee.athleteProfileId){
			this.loadAthleteProfile(attendee.athleteProfileId);
		}else{
			this.athleteDialogForm.athleteProfile = null;
		}
		this.showAthleteDialog = true;
	}
	closeAthleteDialog(): void{
		this.showAthleteDialog = false;
		this.athleteDialogForm.attendee = null;
	}
	async submitAthleteDialog(): Promise<void>{
		this.showAthleteDialogSubmitting = true;
		try{
			const attendeePatch: BAEventAttendeeModel = this.athleteDialogForm.attendee.copy();
			attendeePatch.athleteProfileId = this.athleteDialogForm.athleteProfile.id;
			attendeePatch.athleteProfileShortId = this.athleteDialogForm.athleteProfile.shortId;
			await bestAthletesEventApi.AttendeeApi.patch(attendeePatch);
			this.changed();
		}catch(e){
			console.error("Failed to patch attendee", e);
		}finally{
			this.closeAthleteDialog();
		}
	}
	async loadAthleteProfile(athleteId: string): Promise<void>{
		this.showAthleteDialogLoading = true;
		try{
			const athlete = await athleteApi.publicFindById(athleteId);
			this.athleteDialogForm.athleteProfile = athlete;
		}catch(e){
			console.error("Failed to load athlete profile", e);
		}finally{
			this.showAthleteDialogLoading = false;
		}
	}
	showAthleteDialogReady: boolean = false;
	showAthleteDialogLoading: boolean = false;
	showAthleteDialogSubmitting: boolean = false;
	showAthleteDialog: boolean = false;
	search: string = "";
	athleteDialogForm:{ valid: boolean, attendee: BAEventAttendeeModel | null, athleteProfile: AthleteProfileModel | null } = {
		valid: false,
		attendee: null,
		athleteProfile: null,
	};
	athleteItems: AthleteProfileModel[] = [];
	get SearchItems(): AthleteProfileModel[]{
		if(this.athleteDialogForm.athleteProfile === null) return this.athleteItems;
		return [
			this.athleteDialogForm.athleteProfile,
			...this.athleteItems,
		]
	}

	@Watch('search') debounceSearchAthletes(): void{
		this.showAthleteDialogLoading = true;
		this.debounceCallback('searchAthletes', this.searchAthletes.bind(this), 150);
	}
	
	async searchAthletes(): Promise<void>{
		this.showAthleteDialogLoading = true;
		try{
			const athleteSearch = await athleteApi.queryAll({
				search: this.search,
				fields: [
					'firstName',
					'lastName',
					'email',
				]
			},{
				limitPerPage: 8,
			});
			this.athleteItems = athleteSearch.docs;
		}catch(e){
			console.error('Failed to query athletes', e);
		}finally{
			this.showAthleteDialogLoading = false;
		}
	}
	
	goBack(): void{
		window.history.back();
	}
}
