
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { DataTableHeader, DataOptions } from 'vuetify';
import * as AdminRoutes from '@/../types/constants/admin.routes';
import { DataTableSortMixin, VuetifyMixin, BAIconsMixin } from '../../mixins';
import { BestAthletesEventModel } from '../../models/bestAthletesEvent/BestAthletesEventModel'
import DatePreview from '../ui/DatePreview.vue';
import SortIcon from '../ui/SortIcon.vue';
import { getShortMonthName, getDate, formatDatePrettyLong } from '../../helpers/date';
import { title, eventStatusColor } from '../../pipes';
import FormatEventGross from './FormatEventGross.vue';
import { EventbriteEventStatus } from '@/../types/enums';

const DEFAULT_OPTIONS = {
	page: 0,
	itemsPerPage: 5,
	sortBy: [],
	sortDesc: [false],
	groupBy: [],
	groupDesc: [false],
	multiSort: false,
	mustSort: false,
}

@Component({
	components: { DatePreview, FormatEventGross, SortIcon },
})
export default class BestAthletesEventList extends Mixins(DataTableSortMixin, VuetifyMixin, BAIconsMixin){
	getDate = getDate;
	getShortMonthName = getShortMonthName;
	formatDatePrettyLong = formatDatePrettyLong;
	title = title;
	eventStatusColor = eventStatusColor;

	@Prop({ type: Boolean, default: false }) loading: boolean;
	@Prop({ default: () => ([]) }) statusFilter: EventbriteEventStatus[];
	@Prop() eventList: BestAthletesEventModel[];
	@Prop() search: string;
	@Prop() totalEvents: number;
	@Prop({
		default: () => DEFAULT_OPTIONS
	}) tableOptions: DataOptions;
	updateOptions(options: DataOptions): void{
		this.$emit('update:table-options', options);
	}
	updatePage(page: number): void{
		this.updateOptions({
			...this.tableOptions,
			page,
		});
	}

	openEvent(event: BestAthletesEventModel): void{
		this.$router.push({
			name: AdminRoutes.EventDashboard,
			params:{
				eventId: event.id
			}
		});
	}

	get Loading(): boolean{
		return this.loading;
	}

	headers: DataTableHeader<any>[] = [
		{
			text: 'Date',
			value: 'start',
			sortable: true,
		},
		{
			text: 'Event Name',
			value: 'name',
			sortable: true,
		},
		{
			text: 'Sold',
			value: 'Sold',
			sortable: false,
		},
		{
			text: 'Gross',
			value: 'Gross',
			sortable: false,
		},
		{
			text: 'Status',
			value: 'status',
			sortable: true,
		},
	];

	get TableSortBy(): string{
		const [value] = this.tableOptions.sortBy;
		return value;
	}
	get TableSortDesc(): boolean{
		const [value] = this.tableOptions.sortDesc;
		return value;
	}

	eventCity(item: BestAthletesEventModel, replace: string = "--"): string{
		if(item.onlineEvent){
			return "Online Event";
		}
		if(item.venue){
			return item.venue.address.city;
		}
		return replace;
	}

	formatTicketsSold(event: BestAthletesEventModel): string{
		return `${event.TotalTicketsSold}/${event.TotalTickets}`;
	}
}
