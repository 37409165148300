
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { VuetifyMixin, BAIconsMixin, FormRulesMixin, StatusMixin, StringsMixin } from '@/mixins';
import { EventLocationModel } from "@/models/baEvent";
import { baEventsStore, notificationStore } from '@/store'
import { DataTableHeader } from 'vuetify';
import FileUploadArea from '@/components/forms/FileUploadArea.vue';

@Component({
	components: {
		Page,
        FileUploadArea,
	}
})
export default class BAEventLocationAdminPage extends Mixins(VuetifyMixin, StringsMixin, FormRulesMixin, StatusMixin, BAIconsMixin){
    loadingEventLocations: boolean = true;
    eventLocations: EventLocationModel[];
    locationSearch = "";
    
    mounted() {
        this.loadEventLocations();
    }
    async loadEventLocations() {
        this.loadingEventLocations = true;
        try {
            await baEventsStore.loadEventLocations();
            this.eventLocations = baEventsStore.eventLocations;
        } catch(e) {
            notificationStore.snackbarShowError(`Error loading locations: ${e}`);
        }
        this.loadingEventLocations = false;
    }
    get IsLoading(): boolean {
        return this.loadingEventLocations || baEventsStore.loadingEventLocations;
    }

    get Headers(): DataTableHeader<any>[] {
        return [
            {text: 'Name', value: 'name'},
            {text: 'City', value: 'city'},
            {text: 'State', value: 'state'},
            {text: 'Country', value: 'country'},
            {text: 'Website', value: 'url'},
            {text: 'Google', value: 'google'},
            {text: '', width:"50", value: 'actions', sortable: false},
            {text: '', width:"25", value: 'data-table-expand'}
        ]
    }

    editTitle: string = "Edit Location";
    backupLocation: EventLocationModel;
    editLocation: EventLocationModel = new EventLocationModel;
    editingLocation: boolean = false;
    onAddLocation() {
        this.editTitle = "Add Location";
        this.editLocation = new EventLocationModel();
        this.editingLocation = true;
    }
    onEditLocation(location: EventLocationModel) {
        this.editTitle = `Edit ${location.name}`;
        this.backupLocation = new EventLocationModel;
        Object.assign(this.backupLocation, location);
        this.editLocation = location;
        this.editingLocation = true;
    }
    async onDeleteLocation(location: EventLocationModel) {
        if( !confirm(`Delete ${location.name}? This action cannot be undone`) ) return;

        await baEventsStore.deleteEventLocation(location);
        this.loadEventLocations();
    }

    onCancelEditDlg() {
        Object.assign(this.editLocation, this.backupLocation);
        this.editingLocation = false;
    }
    async onSaveEditDlg() {
        if( !(this.$refs.form as HTMLFormElement).validate() ) {
            this.setError( "Please complete all of the required fields" );
            return;
        }

        await baEventsStore.saveEventLocation(this.editLocation);
        this.editingLocation = false;
        this.loadEventLocations();
    }
    async onChangeLocationOnline() {
        this.editLocation.address = this.editLocation.online? "Online" : this.backupLocation.address;
    }

	gotoEvents() {
		this.$router.push('/baevent-admin');
	}
}
