
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { AppHostnameMixin, BAIconsMixin, FormRulesMixin, RoutingMixin, StatusMixin, StringsMixin, VuetifyMixin } from '@/mixins';
import { BAEventModel, EventLocationModel } from '@/models/baEvent';
import { baEventsStore, notificationStore } from '@/store'
import { DataTableHeader } from 'vuetify';
import FileUploadArea from '@/components/forms/FileUploadArea.vue';
import { BAEventAdminDetail, BAEventDetail } from '@/../types/constants/admin.routes'
import DatePicker from '@/components/forms/DatePicker.vue';
import TimePickerDialog from "@/components/calendar/TimePickerDialog.vue";
import BAEventShare from '@/views/admin/BAEventShare.vue'
import CopyToClipboard from '@/components/ui/CopyToClipboard.vue';
import { AgreementFile } from '@/models/file/FileModel';

@Component({
	components: {
		Page,
        FileUploadArea,
		TimePickerDialog,
        BAEventShare
	}
})
export default class BAEventsAdminDashboard extends Mixins(StringsMixin, VuetifyMixin, BAIconsMixin, RoutingMixin, AppHostnameMixin, StatusMixin, FormRulesMixin){
    loadingBAEvents: boolean = true;
    baEvents: BAEventModel[];
    eventsSearch = "";
	selectLocation: EventLocationModel = new EventLocationModel;
    
    mounted() {
        this.loadBAEvents();
    }
    async loadBAEvents() {
        this.loadingBAEvents = true;
        try {
			await baEventsStore.loadEventLocations();
            await baEventsStore.loadBAEvents();
            this.baEvents = baEventsStore.baEvents;
        } catch(e) {
            notificationStore.snackbarShowError(`Error loading BA events: ${e}`);
        }
        this.loadingBAEvents = false;
    }
    get IsLoading(): boolean {
        return this.loadingBAEvents || baEventsStore.loadingBAEvents;
    }

    get Headers(): DataTableHeader<any>[] {
        return [
            {text: 'Name', value: 'name'},
            {text: 'Location', value: 'location'},
			{text: 'Date', value: 'date'},
			{text: 'Participants', value: 'participants', sortable: false},
			{text: '', value: 'view', sortable: false},
            {text: '', width:"50", value: 'actions', sortable: false},
            {text: '', width:"25", value: 'data-table-expand'}
        ]
    }
	get Locations(): EventLocationModel[] {
		return baEventsStore.eventLocations;
	}

    editTitle: string = "Edit Event";
    backupEvent: BAEventModel;
    editEvent: BAEventModel = new BAEventModel;
	editEventDate: Date;
	editEventTime: string;
    editingEvent: boolean = false;
	startEditEvent() {
		this.editEventDate = new Date(this.editEvent.DateStr);
		this.editEventTime = this.editEvent.TimeStr;
		this.unlimitedCapacity = (this.editEvent.capacity < 0);
		this.selectLocation = this.editEvent.Location;
		this.editingEvent = true;
	}
    onAddEvent() {
        this.editTitle = "Add Event";
        this.editEvent = new BAEventModel();
		this.startEditEvent();
    }
    onEditEvent(baEvent: BAEventModel) {
        this.editTitle = `Edit ${baEvent.name}`;
        this.backupEvent = new BAEventModel().load(baEvent);
        this.editEvent = baEvent;
        if( this.IsEmpty( this.editEvent.dataLabels )) {
            this.editEvent.dataLabels = ['First Name', 'Last Name', 'Email', 'Comments'];
        }
		this.startEditEvent();
    }
	onViewEvent(baEvent: BAEventModel) {
		this.$router.push({
			name: BAEventAdminDetail,
			params: { eventId: baEvent.id }			
		});
	}
	onPreviewEvent(baEvent: BAEventModel) {
		this.$router.push({
			name: BAEventDetail,
			params: { eventId: baEvent.id }			
		});
	}
    onAttendeeCheckIn(baEvent: BAEventModel) {
        const url = `${this.AdminLInk}/baevent-redeem?userId=none&eventId=${baEvent.id}&ticketId=all`;
        this.gotoUrl(url);
    }
    async onDeleteEvent(baEvent: BAEventModel) {
        if( !confirm(`Delete ${baEvent.name}? This action cannot be undone`) ) return;

        await baEventsStore.deleteBAEvent(baEvent);
        this.loadBAEvents();
    }

    newAgreement: AgreementFile = new AgreementFile('My Agreement');
    newAgreementIndex: number = 1;
    agreementUnderPreview: AgreementFile;
    agreementPreview: boolean = false;
    onRemoveAllAgreements() {
        this.editEvent.agreements = [];
    }
    onRemoveAgreement(index: number) {
        this.editEvent.agreements.splice(index, 1);
    }
    async onNewAgreement() {
        if( this.IsEmpty(this.newAgreement) ) return;
        this.editEvent.agreements.push(this.newAgreement);
        await baEventsStore.saveBAEvent(this.editEvent);
        this.newAgreementIndex++;
        this.newAgreement = new AgreementFile(`My Agreement ${this.newAgreementIndex}`);
    }
    async onPreviewAgreement(agreement) {
        this.agreementUnderPreview = agreement;
        await this.agreementUnderPreview.read();
        this.agreementPreview = true;
    }
    onClosePreview() {
        this.agreementPreview = false;
    }

    newDataLabel: string = '';
    onRemoveAllDataLabels() {
        this.editEvent.dataLabels = [];
    }
    onRemoveDataLabel(index: number) {
        this.editEvent.dataLabels.splice(index, 1);
    }
    onNewDataLabel() {
        if( this.IsEmpty(this.newDataLabel) ) return;
        if( this.editEvent.dataLabels.includes(this.newDataLabel) ) return;

        const commentsIndex = this.editEvent.dataLabels.indexOf('Comments');
        if( commentsIndex > 0 ) {
            this.editEvent.dataLabels.splice(commentsIndex, 0, this.newDataLabel);
        } else {
            this.editEvent.dataLabels.push(this.newDataLabel);
        }
    } 

    unlimitedCapacity: boolean;
	onChangeUnlimitedCapacity() {
		this.editEvent.capacity = this.unlimitedCapacity? -1 : (( !this.backupEvent || this.backupEvent.capacity < 0 )? 100 : this.backupEvent.capacity );
	}

    onCancelEditDlg() {
        if( this.backupEvent ) {
            Object.assign(this.editEvent, this.backupEvent);
        }
        this.clearStatus();
        this.editingEvent = false;
    }
    savingEvent: boolean = false;
    async onSaveEditDlg(): Promise<BAEventModel> {
        this.savingEvent = true;
        if( !this.$refs.form.validate() ) {
            this.setError( "Event creation incomplete. Please complete all of the required fields" );
            this.savingEvent = false;
            return;
        }

		this.editEvent.date = new Date(this.formatDateSlashesYYYYMMDD(this.editEventDate, true) + ` ${this.editEventTime}`);
		this.editEvent.location = this.selectLocation? this.selectLocation.id : '';

        const newEvent = await baEventsStore.saveBAEvent(this.editEvent);
        this.editingEvent = false;
        await this.loadBAEvents();
        this.savingEvent = false;

        return newEvent;
    }

	get EarliestTime(): Date {
		return new Date(`${this.formatDateSlashesYYYYMMDD(this.editEvent.date)} 7:00 am`);
	}

    $refs:{ form: HTMLFormElement }

	gotoEvents() {
		this.$router.push('/baevent-admin');
	}
	onEditSponsors() {
		this.$router.push('/baeventsponsors');
	}
	onEditLocations() {
		this.$router.push('/baeventlocations');
	}
}
