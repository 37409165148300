
import { EventAdmin, EventOrderAdmin } from '@/../types/constants/admin.routes';
import { EventDetails } from '@/../types/constants/web_client_user.routes';
import { Component, Mixins } from 'vue-property-decorator';
import { mdiPencil, mdiSync, mdiDownload } from '@mdi/js';
import { eventsAdminStore } from '../../store';
import { FormRulesMixin, PollingMixin, VuetifyMixin, AppHostnameMixin, BAIconsMixin } from '../../mixins';
import Page from '../../views/Page.vue'
import Alert from '../ui/Alert.vue'
import SortIcon from '../ui/SortIcon.vue'
import UserChip from '../ui/UserChip.vue'
import ProgressBar from '../charts/ProgressBar.vue'
import BestAthletesEventAttendeeList from './BestAthletesEventAttendeeList.vue'
import FormatEventGross from './FormatEventGross.vue'
import { BAEventTicketClassModel, BestAthletesEventModel, BAEventAttendeeModel } from '../../models/bestAthletesEvent';
import { formatDatePrettyLong } from '../../helpers/date';
import { title, eventStatusColor } from '../../pipes';
import { EventbriteEventStatus } from '@/../types/enums';
import { ContextMenuItem } from '@/../types/interfaces';
import { bestAthletesEventApi } from '../../api/BestAthletesEventApi';
import { SWTemplate } from '@/../types/interfaces/SmartwaiverTypes';

@Component({
	components: {
		Alert,
		BestAthletesEventAttendeeList,
		FormatEventGross,
		SortIcon,
		UserChip,
		Page,
		ProgressBar,
	}
})
export default class BestAthletesEventDashboard extends Mixins(FormRulesMixin, AppHostnameMixin, PollingMixin, VuetifyMixin, BAIconsMixin){
	mdiPencil = mdiPencil;
	title = title;
	eventStatusColor = eventStatusColor;
	formatDatePrettyLong = formatDatePrettyLong;

	get ChildRouteProps(): Record<string, any>{
		return {
			props:{
				event: this.Event,
			},
			on:{
				'open-order': (eventbriteOrderId: string) => {
					this.$router.push({
						name: EventOrderAdmin,
						params:{
							eventbriteOrderId,
						}
					})
				},
				'changed': () => eventsAdminStore.loadCurrentEventById(this.Event.id)
			}
		};
	}

	syncLoading: boolean = false;
	get Loading(): boolean{
		return this.syncLoading || eventsAdminStore.currentEventLoading;
	}
	created(): void{
		this.pollIntervalMs = 15000;
		this.pollCallback(() => {
			eventsAdminStore.loadCurrentEventById(this.Event.id);
		});
		eventsAdminStore.loadSmartWaiverTemplates();
	}
	get LiveStatus(): string{
		return EventbriteEventStatus.Live;
	}
	get DraftStatus(): string{
		return EventbriteEventStatus.Draft;
	}
	get Event(): BestAthletesEventModel{
		if(eventsAdminStore.currentEvent === null){
			return new BestAthletesEventModel();
		}
		return eventsAdminStore.currentEvent;
	}
	get EventName(): string | null{
		return this.Event.name;
	}
	get EventIsLive(): boolean{
		return this.Event.status === this.LiveStatus;
	}
	get EventStart(): Date | null{
		return this.Event.start;
	}
	get EventLocation(): string | null{
		if(this.Event.venue === null){
			return null;
		}
		return this.Event.venue.name;
	}
	get EventAttendeeCount(): number{
		return this.Event.attendees.length;
	}
	get EventStatus(): EventbriteEventStatus{
		return this.Event.status as EventbriteEventStatus;
	}
	get EventbriteAdminUrl(): string{
		return `https://www.eventbrite.ca/myevent?eid=${this.Event.eventbriteId}`;
	}
	get EventbriteTicketsUrl(): string{
		return `https://www.eventbrite.ca/manage/events/${this.Event.eventbriteId}/tickets/create`;
	}
	get EventContextMenu(): ContextMenuItem[]{
		return [
			{
				text: 'Sync Event & Waivers',
				icon: mdiSync,
				click: () => this.eventbriteSync(),
			},
			{
				text: 'Download Attendee List',
				icon: mdiDownload,
				click: () => this.downloadAttendeeList(),
			}
		]
	}
	async eventbriteSync(): Promise<void>{
		this.syncLoading = true;
		try{
			await bestAthletesEventApi.eventbriteSync({
				eventIds: [ this.Event.eventbriteId ],
				synchronous: true,
			});
			await eventsAdminStore.loadCurrentEventById(this.Event.id);
		}catch(e){
			console.error("Syncing Event Failed", e);
		}finally{
			this.syncLoading = false;
		}
	}
	getAthleteId(attendee: BAEventAttendeeModel): string{
		if(attendee.athleteProfileShortId) return attendee.athleteProfileShortId;
		if(attendee.athleteProfileId) return attendee.athleteProfileId;
		return 'N/A';
	}
	async downloadAttendeeList(): Promise<void>{
		const attendees = this.Event.PopulatedAttendees.map(a => ([
			this.getAthleteId(a),
			a.eventbriteOrderId,
			a.eventbriteTicketClassName,
			a.name,
		]));
		const headers = [
			'Athlete Id',
			'Order Id',
			'Ticket Name',
			'Name',
		];
		const csv = [headers, ...attendees].map(row => row.join(',')).join('\n');
		const blob = new Blob([csv]);
		let dl = document.createElement('a');
		dl.href = window.URL.createObjectURL(blob);
		dl.download = `${this.EventName}_attendees.csv`;
		dl.click();
	}
	openInEventbrite(): void{
		window.open(this.EventbriteAdminUrl,'_');
	}
	get EventUrl(): string{
		return `https://${this.AthleteAppUrl}/event/${this.Event.id}`;
	}
	openPreview(): void{
		this.$router.push({
			name: `${EventAdmin}${EventDetails}`,
			params:{
				eventId: this.Event.id
			}
		})
	}
	get EventTicketClasses(): BAEventTicketClassModel[]{
		return this.Event.PopulatedTicketClassses;
	}
	get TotalTickets(): number{
		return this.Event.TotalTickets;
	}
	get TotalTicketsSold(): number{
		return this.Event.TotalTicketsSold;
	}
	get TicketsSoldProgressColor(): string{
		if(this.TotalTickets === 0) return this.getColor('baColorGray9');
		return this.getColor('baColorGreen1');
	}
	get TicketsSoldProgress(): number{
		if(this.TotalTickets === 0) return 100;
		const progress = ~~((this.TotalTicketsSold / this.TotalTickets) * 100);
		if(progress === 0){
			return 0;
		}else if(progress < 5){
			return 5;
		}
		return progress;
	}


	showWaiverDialog = false;
	waiverDialog: { waiverTemplates: Array<SWTemplate | null>, noWaivers: boolean, valid: boolean } = {
		waiverTemplates: [null],
		noWaivers: false,
		valid: false,
	}
	/**
	 * Only Published Waivers
	 */
	get AvailableWaiverTemplates(): SWTemplate[]{
		return eventsAdminStore.smartWaiverTemplates.filter(w => w.publishedVersion !== -1);
	}
	get SmartWaiverTemplatesLoading(): boolean{
		return eventsAdminStore.smartWaiverTemplatesLoading;
	}
	openWaiverDialog(): void{
		this.waiverDialog.waiverTemplates = this.Event.waivers.waiverTemplates.slice();
		this.waiverDialog.noWaivers = this.Event.waivers.noWaivers;
		this.showWaiverDialog = true;
	}
	cancelWaiverDialog(): void{
		this.waiverDialog.waiverTemplates = this.Event.waivers.waiverTemplates.slice();
		this.showWaiverDialog = false;
	}
	async submitWaiverDialog(): Promise<void>{
		await eventsAdminStore.setWaiverTemplates({ waiverTemplates: this.waiverDialog.waiverTemplates, noWaivers: this.waiverDialog.noWaivers });
		this.showWaiverDialog = false;
	}
	
	get NeedsWaivers(): boolean{
		return this.Event.WaiversNeeded;
	}
	get RequiredWaivers(): SWTemplate[]{
		return this.Event.waivers.waiverTemplates;
	}

	get WaiverMenuItems(): ContextMenuItem[]{
		return [
			{
				text: "Edit Waivers",
				icon: mdiPencil,
				click: () => this.openWaiverDialog(),
			},
		]
	}
}
