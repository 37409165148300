import { CrudApi } from './CrudApi';
import { EventAssessmentDataModel } from '../models/athlete/EventAssessmentDataModel';

class EventAssessmentDataApi extends CrudApi<EventAssessmentDataModel> {
	constructor(){
		super('eventAssessment', (obj) => new EventAssessmentDataModel().load(obj));
	}

    async findAssessment(assessment: EventAssessmentDataModel): Promise<EventAssessmentDataModel> {
        const eventAssessments = await this.queryAll(
            { $match: { athleteId: assessment.athleteId } },
            { } );
        if( eventAssessments.count == 0 ) return assessment;
        for( const eventAssessment of eventAssessments.docs ) {
            if( eventAssessment.eventId == assessment.eventId ) {
                const returnAssessment: EventAssessmentDataModel = new EventAssessmentDataModel;
                Object.assign(returnAssessment, {...eventAssessment, assessmentDate: assessment.assessmentDate });
                return returnAssessment;
            }
        }
        return assessment;
    }
}

export const eventAssessmentDataApi = new EventAssessmentDataApi();