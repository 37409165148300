import { render, staticRenderFns } from "./EventAdminPage.vue?vue&type=template&id=d6f22b12&scoped=true&"
import script from "./EventAdminPage.vue?vue&type=script&lang=ts&"
export * from "./EventAdminPage.vue?vue&type=script&lang=ts&"
import style0 from "./EventAdminPage.vue?vue&type=style&index=0&id=d6f22b12&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6f22b12",
  null
  
)

export default component.exports