
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { DataTableSortMixin, VuetifyMixin } from '../../mixins';
import SortIcon from '../ui/SortIcon.vue';
import UserChip from '../ui/UserChip.vue';
import ProfilePictureProvider from '../hoc/ProfilePictureProvider.vue';
import { BAEventAttendeeModel, BestAthletesEventModel } from '../../models/bestAthletesEvent';

interface AttendeeDataTableItem{
	id: string,
	eventbriteOrderId: string,
	name: string,
	athleteProfileId: string | null,
	ticketClassName: string,
	waiverStatus: string,
}

@Component({
	components: { SortIcon, UserChip, ProfilePictureProvider },
})
export default class BestAthletesEventAttendeeList extends Mixins(DataTableSortMixin, VuetifyMixin){

	@Prop({ default: null }) event: BestAthletesEventModel | null;

	get AttendeeItems(): AttendeeDataTableItem[]{
		if(this.event === null) return [];
		return this.event.PopulatedAttendees.map(a => ({
			id: a.id,
			eventbriteOrderId: a.eventbriteOrderId,
			name: a.name,
			athleteProfileId: a.athleteProfileId,
			ticketClassName: a.eventbriteTicketClassName,
			waiverStatus: this.getWaiverStatus(a),
		}));
	}

	getWaiverStatus(attendee: BAEventAttendeeModel): string{
		if(this.event.waivers.noWaivers) return "N/A";
		const order = this.event.PopulatedOrders.find(o => o.eventbriteId === attendee.eventbriteOrderId);
		if(order === undefined) return "Unknown";
		const signedWaivers = this.event.waivers.waiverTemplates.map(w => order.waiverIsSigned(w.templateId)).filter(signed => signed).length;
		const totalWaivers = this.event.waivers.waiverTemplates.length;
		return `${signedWaivers} of ${totalWaivers} signed`
	}

	openOrder(eventbriteOrderId: string): void{
		const url = `https://www.eventbrite.ca/reports?eid=${this.event.eventbriteId}&rid=h&filterby=all,${eventbriteOrderId}`;
		window.open(url, '_blank');
	}

	headers: DataTableHeader<any>[] = [
		{
			text: 'Attendee',
			value: 'name',
			sortable: true,
		},
		{
			text: 'Ticket Type',
			value: 'TicketType',
		},
		{
			text: 'Waiver Status',
			value: 'status',
		},
		{
			text: 'Order #',
			value: 'eventbriteOrderId',
			sortable: false,
		},
	];
}
