
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { StringsMixin, VuetifyMixin, BAIconsMixin } from '@/mixins';
import { DataTableHeader } from 'vuetify';
import { eventsAdminStore, notificationStore } from '@/store';
import { BAEventAttendeeModel, BestAthletesEventModel } from '@/models/bestAthletesEvent'
import { formatDatePrettyLong, formatDatePrettyShort } from '@/helpers/date';
import { athleteApi } from '@/api/AthleteApi';
import { QueryOptions, RepositoryQuery } from '@/../types/interfaces';
import { EventAssessmentDataModel } from '@/models';
import RecruitingProfileInfo from '@/components/profile/recruiting/RecruitingProfileInfo.vue';
import { eventAssessmentDataApi } from '@/api/EventAssessmentDataApi'
import { BAEventOrderModel } from '@/models/bestAthletesEvent/BAEventOrderModel';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import { BaseSoccerPosition, DominantSide } from '@/../types/enums';
import { Gender } from '@best-athletes/ba-types';

@Component({
	components: {
		Page,
		RecruitingProfileInfo,
	}
})
export default class PhysicalEventsDataEntryPage extends Mixins(VuetifyMixin, StringsMixin, BAIconsMixin) {
	formatDate = formatDatePrettyShort;
	Gender = Gender;
	Position = BaseSoccerPosition;
	DominantSide = DominantSide;

    search = "";

	get Event(): BestAthletesEventModel {
		if(eventsAdminStore.currentEvent === null){
			return new BestAthletesEventModel();
		}
		return eventsAdminStore.currentEvent;
	}
    get EventName(): string {
        return this.Event.name;
    }
    get EventStart(): string {
        return formatDatePrettyLong(this.Event.start, true, false);
    }
    get EventLocation(): string {
		if( !this.Event.venue ) return undefined;
        return this.Event.venue.name;
    }

    get IsLoading(): Boolean {
        return this.eventLoading;
    }
    get IsReady(): Boolean {
        return !this.IsLoading;
    }
	
    mounted(): void {
        this.loadEvent();
    }

    eventLoading: Boolean = true;
    async loadEvent() {
        this.eventLoading = true;
		try{
			await this.loadEvents();
			if( eventsAdminStore.totalEvents > 0 ) {
				await eventsAdminStore.loadCurrentEventById(eventsAdminStore.eventList[0].id);
				await this.loadAthletesAttending();
			}
		} catch(e) {
			console.error(e);
		} finally {
        	this.eventLoading = false;
		}
    }

	eventsLoading: Boolean;
	async loadEvents() {
		this.eventsLoading = true;
		try{
			const query: RepositoryQuery<BestAthletesEventModel> = {search: "", fields: [] };
			const options: QueryOptions = { limitPerPage: 1, sort: {fields: [{field: 'status', desc: true}]} };
			await eventsAdminStore.loadEventList({ query, options });
		}catch(e){
			console.error(e);
		}finally{
			this.eventsLoading = false;
		}
	}

	NewAssessment(order: BAEventOrderModel, attendee: BAEventAttendeeModel, athlete: AthleteProfileModel): EventAssessmentDataModel {
		var assessment: EventAssessmentDataModel = new EventAssessmentDataModel;
		Object.assign(assessment, {
						athleteId: athlete.id,
						organizationId: athlete.organizationId,
						teamId: attendee.eventbriteTicketClassName.split('-')[0],
						assessmentDate: this.Event.start,
						shortId: athlete.shortId,
						firstName: athlete.firstName,
						lastName: athlete.lastName,
						email: athlete.email,
						// playingLevel: AgeGroup
						// competitiveLevel: number
						gender: athlete.gender,
						dominantFoot: athlete.dominantSide,
						playingPosition: athlete.primaryPosition,
						dateOfBirth: athlete.BirthDate,
						mass: athlete.weight,
						standingHeight: athlete.height,
						// sittingHeightWithBox
						// counterMovementJumpHeight
						// dropJumpContactTime
						// dropJumpHeight
						// tenMeterSprint
						// twentyMeterSprint
						// thirtyFiveMeterSprint
						// yoyoIntermittentRecoveryTestStage
						eventId: order.eventbriteEventId,
						status: attendee.status,
						eventbriteTicketClassName: attendee.eventbriteTicketClassName,
					});

		return assessment;
	}
	athletesAttending: Array<EventAssessmentDataModel> = [];
	get AttendingAthletes(): Array<EventAssessmentDataModel> {
		return this.athletesAttending;
	}
	async loadAthletesAttending() {
		const order = new BAEventOrderModel;
		const attendee = new BAEventAttendeeModel;
		const athlete = new AthleteProfileModel;
		athlete.id = 'ccm0001';
		athlete.organizationId = 'red';
		attendee.eventbriteTicketClassName = 'RED - BU17';
		athlete.shortId = '1234';
		athlete.firstName = "Ky";
		athlete.lastName = "Mont";
		athlete.email = "k.m@gmail.com";
		athlete.gender = Gender.Male;
		athlete.dominantSide = DominantSide.Right;
		athlete.primaryPosition = BaseSoccerPosition.Midfielder;
		// athlete.BirthDate = new Date();
		// athlete.weight = ;
		// athlete.height = ;
		order.eventbriteEventId = 'abcd';
		attendee.status = 'Attending';
		const assessment = this.NewAssessment(order, attendee, athlete);
		const editAssessment = await eventAssessmentDataApi.findAssessment(assessment)
		this.athletesAttending.push(editAssessment? editAssessment : assessment);
		// end CCM_PHYSICAL_DEBUG
		for( const attendee of this.Event.PopulatedAttendees ) {
			if( attendee.status == 'Deleted') continue;

			const order = this.Event.PopulatedOrders.find(o => o.eventbriteId === attendee.eventbriteOrderId);
			if( !order ) continue;

			if( !order.userId || order.userId == '' ) {
				continue; // CCM_PHYSICAL_DEBUG - while debugging
				const athlete = await athleteApi.findByContact({
					email: order.email,
					firstName: order.firstName,
					lastName: order.lastName
				})
				if( !athlete ) continue;

				const assessment = this.NewAssessment(order, attendee, athlete);
				this.athletesAttending.push(assessment);
			}
			else {
				const athletes = await athleteApi.queryAll(
					{ $match: { ['users.id']: order.userId } as any }, 
					{ limitPerPage: 0 }
					);
				if( athletes.count == 0 ) continue;

				for( const athlete of athletes.docs ) {
					const assessment = this.NewAssessment(order, attendee, athlete);
					const editAssessment = await eventAssessmentDataApi.findAssessment(assessment)
					this.athletesAttending.push(editAssessment? editAssessment : assessment);
				}
			}
		}
	}

	get AttendeesHeaders(): DataTableHeader<any>[] {
		return [
			{
				text: 'Athlete',
				value: 'FullName'
			},
			{
				text: 'Group',
				value: 'eventbriteTicketClassName'
			},
			{
				text: 'Jersey Color',
				value: 'idcolor'
			},
			{
				text: 'Jersey Number',
				value: 'idnumber'
			},
			{
				text: 'Actions', 
				value: 'actions',
				sortable: false,
			},
			{
				text: 'Notes',
				value: 'notes',
				sortable: false,
			},
			{
				text: '', 
				value: 'data-table-expand',
				sortable: false,
			},
		]
	}

	statusFilter(value: any, search: string | null, item: any): boolean {
		return value != 'Deleted';
	}
	
	isStationCheckin: Boolean = false;
	isStationSprint: Boolean = false;
	isStationJump: Boolean = false;
	isStationYoYo: Boolean = false;
	editingAssessment: Boolean = false;
	currentAssessment: EventAssessmentDataModel;
	backupAssessment: EventAssessmentDataModel = new EventAssessmentDataModel;
	beginEdit(assessment: EventAssessmentDataModel) {
		Object.assign(this.backupAssessment, assessment);
		if( assessment.sprints ) {
			Object.assign(this.backupAssessment.sprints, assessment.sprints);
		}
		if( assessment.jumps ) {
			Object.assign(this.backupAssessment.jumps, assessment.jumps);
		}
		this.currentAssessment = assessment;
	}
	completeEdit() {
		this.isStationCheckin = false;
		this.isStationSprint = false;
		this.isStationJump = false;
		this.isStationYoYo = false;
		this.editingAssessment = false;
	}

	onStationCheckin(assessment: EventAssessmentDataModel) {
		this.beginEdit(assessment);
		this.currentAssessment.checkedIn = true;
		this.isStationCheckin = true;
	}
	onStationSprint(assessment: EventAssessmentDataModel) {
		this.beginEdit(assessment);
		this.isStationSprint = true;
	}
	onStationJump(assessment: EventAssessmentDataModel) {
		this.beginEdit(assessment);
		this.isStationJump = true;
	}
	onStationYoYo(assessment: EventAssessmentDataModel) {
		this.beginEdit(assessment);
		this.isStationYoYo = true;
	}

	onEditAssessment(assessment: EventAssessmentDataModel) {
		this.beginEdit(assessment);
		this.editingAssessment = true;
	}

	async saveEdit() {
		try {
			await eventAssessmentDataApi.save(this.currentAssessment);
			notificationStore.pushSnackbarSuccess({message: `Event Assessment for ${this.currentAssessment.FullName} updated`});
			
		} catch(e) {
			notificationStore.pushSnackbarError({message: `Error Saving Assessment: ${e}`})
		}
		this.completeEdit()
	}
	cancelEdit() {
		Object.keys(this.backupAssessment).map((key) => { this.currentAssessment[key] = this.backupAssessment[key] });
		if( this.backupAssessment.sprints ) Object.assign(this.currentAssessment.sprints, this.backupAssessment.sprints);
		if( this.backupAssessment.jumps ) Object.assign(this.currentAssessment.jumps, this.backupAssessment.jumps);
		this.completeEdit()
	}

	onAddSprintTrial() {
		this.currentAssessment.addSprint();
	}
	get Speed(): string {
		return this.currentAssessment.BestSpeed;
	}
	get Acceleration(): string {
		return this.currentAssessment.BestAcceleration;
	}
	get Fastest35m(): string {
		return ''
		// return this.currentAssessment.Fastest35m;
	}

	onAddJumpTrial() {
		this.currentAssessment.addJump();
	}
	get Power() {
		return this.currentAssessment.BestPower;
	}
}
